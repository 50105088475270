import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { getAuth } from './ortutils';
import { initChannelListener } from './websockethandler';

let EchoInstance = null;

export default {
	install(Vue) {
		Vue.prototype.$initWebSocket = function (token) {
			let authToken;
			const auth = getAuth();
			if (!token) authToken = auth.token;
			else authToken = token;
			return new Promise((resolve) => {
				if (!EchoInstance) {
					window.Pusher = Pusher;
					// engedélyezzük/kikapcsoljuk a pusher logolását
					window.Pusher.logToConsole = process.env.VUE_APP_WEBSOCKET_LOG_TO_CONSOLE === 'true';
					// Inicializáljuk a WebSocketet csak egyszer
					EchoInstance = new Echo({
						broadcaster: 'pusher',
						key: process.env.VUE_APP_WEBSOCKET_APP_KEY,
						cluster: process.env.VUE_APP_WEBSOCKET_APP_CLUSTER,
						wsHost: window.location.hostname,
						// csak localra! - wsHost: 'ortadmintest',
						wsPort: process.env.VUE_APP_WEBSOCKET_APP_PORT,
						wssPort: process.env.VUE_APP_WEBSOCKET_APP_PORT,
						forceTLS: process.env.VUE_APP_WEBSOCKET_APP_FORCETLS === 'true',
						enabledTransports: ['ws', 'wss'],
						enableStats: true,
						encrypted: true,
						// disableStats: true,
						// csak localra! - authEndpoint: 'http://ortadmintest/api/broadcasting/auth',
						auth: {
							headers: {
								// 'x-app-id': process.env.VUE_APP_WEBSOCKET_APP_ID,
								'Authorization': 'Bearer ' + authToken,
								// 'X-CSRF-Token': authToken,
								// 'Accept': 'application/json',
							},
							method: 'post'
						},
					});
					Vue.prototype.$websocket = window.Echo = EchoInstance;
					// Figyeljük az állapot változását
					const checkConnection = () => {
						if (EchoInstance) {
							const state = EchoInstance.connector.pusher.connection.state;
							if (state === 'connected') {
								// Az elérhető csatornák figyelése
								initChannelListener(EchoInstance, auth);
								// Frissítsés az autentikáció után
								this.$forceUpdate();
								// Kiírjuk, hogy csatlakozva a WebSocket szerverhez
								console.log('Kapcsolódva a WebSocket szerverhez...');
								// Feloldjuk a Promiset
								resolve();
							}
							else {
								// Várunk egy rövidebb időt, majd újra ellenőrizzük az állapotot
								setTimeout(checkConnection, 100);
							}
						}
					}
					// Kezdeti ellenőrzés
					checkConnection();
				}
				else {
					// Ha már létezik az EchoInstance, feloldjuk a Promiset
					resolve();
				}
			});
		};

		Vue.prototype.$closeWebSocket = function () {
			if (EchoInstance) {
				// Bezárjuk a WebSocket kapcsolatot
				EchoInstance.disconnect();
				EchoInstance = null;
				console.log('WebSocket kapcsolat lezárva');
			}
		};

	}
}
