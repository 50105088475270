import Vue from 'vue';
import Router from 'vue-router';
import Helper from './routerhelper';
import { getAuth, getDemoDashboard } from '../plugins/ortutils';

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => getDemoDashboard() ? import('@/modules/DemoDashboard') : import('@/modules/Dashboard')
const Login = () => import('@/modules/pages/Login')
// - hamarosan const Register = () => import('@/modules/pages/Register')

// Users
const UsersList = () => import('@/modules/users/UsersList')
const EditUser = () => import('@/modules/users/EditUser')
const EditProfile = () => import('@/modules/users/EditProfile')

// Roles
const GlobalRoleEditor = () => import('@/modules/roleeditors/GlobalRoleEditor')
const GlobalEditARole = () => import('@/modules/roleeditors/GlobalEditRole')
const ProjectRoleEditor = () => import('@/modules/roleeditors/ProjectRoleEditor')
const ProjectEditARole = () => import('@/modules/roleeditors/ProjectEditRole')

//Email Templates
const Emails = () => import('@/modules/emailtemplates/Emails')
const CreateEmail = () => import('@/modules/emailtemplates/CreateEmail')
const EditEmail = () => import('@/modules/emailtemplates/EditEmail')
const ShowEmail = () => import('@/modules/emailtemplates/ShowEmail')
const SendEmail = () => import('@/modules/emailtemplates/SendEmail')

//Notifications
const NotificationList = () => import('@/modules/notifications/NotificationList')
const EditNotification = () => import('@/modules/notifications/EditNotification')

//Text Contens
const TextContentList = () => import('@/modules/textcontents/TextContentList')
const EditTextContent = () => import('@/modules/textcontents/EditTextContent')

// Clients
const Clients = () => import('@/modules/clients/Clients')
const AddClient = () => import('@/modules/clients/AddClient')
const EditClient = () => import('@/modules/clients/EditClient')

// Project Designer
const ProjectDesigner = () => import('@/modules/projectdesigner/ProjectDesigner')
const EditProject = () => import('@/modules/projectdesigner/EditProject')
const ProjectResults = () => import('@/modules/projectdesigner/ProjectResults')
const ProjectLogs = () => import('@/modules/projectdesigner/ProjectLogs')
const ProjectDiags = () => import('@/modules/projectdesigner/ProjectDiags')

// Survey Designer
const SurveyDesigner = () => import('@/modules/surveydesigner/SurveyDesigner')
const SurveySelector = () => import('@/modules/surveydesigner/SurveySelector')
const EditSurvey = () => import('@/modules/surveydesigner/EditSurvey')

// Article Editor
const ArticleEditor = () => import('@/modules/contenteditor/articleeditor/ArticleEditor')
const EditArticle = () => import('@/modules/contenteditor/articleeditor/EditAnArticle')

// FAQ Editor
const FaqEditor = () => import('@/modules/contenteditor/faqeditor/FaqEditor')
const AddNewFaq = () => import('@/modules/contenteditor/faqeditor/AddNewFaq')
const EditaFaq = () => import('@/modules/contenteditor/faqeditor/EditaFaq')

// Newsletter Subscribers
const NewsletterSubscribers = () => import('@/modules/contenteditor/NewsletterSubscribers')

// Researcher Subscribers
const ResearcherSubscribers = () => import('@/modules/contenteditor/ResearcherSubscribers')

// Octopus Researchers
const OctopusResearchers = () => import('@/modules/contenteditor/octopus-researchers/OctopusResearchers')
const EditResearcher = () => import('@/modules/contenteditor/octopus-researchers/EditAResearcher')

// Settings
const Settings = () => import('@/modules/settings/Settings')

//403
const Page403 = () => import('@/modules/pages/Page403')

//404
const Page404 = () => import('@/modules/pages/Page404')

//500
const Page500 = () => import('@/modules/pages/Page500')

Vue.use(Router)

let router = new Router({
	mode: 'hash', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'active',
	scrollBehavior: () => ({ y: 0 }),
	routes: configRoutes()
});

router.beforeEach((to, from, next) => {
	const auth = getAuth();
	if (!auth && !Helper.right && to.name !== 'Login') next({name: 'Login'});
	else {
		Helper.funcNext = next;
		next();
	}
});

export default router;

function configRoutes () {
	return [
		{
			path: '/',
			redirect: '/dashboard',
			name: 'Home',
			component: TheContainer,
			children: [
				{
					path: 'dashboard',
					meta: {
						label: 'Dashboard',
					},
					component: Dashboard
				},
				{
					path: 'projectdesigner',
					meta: {
						label: 'Project Designer',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'list_projects');
					},
					children: [
						{
							path: '',
							component: ProjectDesigner,
						},
						{
							path: ':id/edit',
							meta: { label: 'Edit a project' },
							name: 'Edit a project',
							component: EditProject,
						},
						{
							path: '/projectdesigner/create',
							meta: { label: 'Create a new project' },
							name: 'Create Project',
							component: EditProject,
						},
						{
							path: '/projectdesigner/result/:id',
							meta: { label: 'Results of a project' },
							name: 'Project Results',
							component: ProjectResults,
						},
						{
							path: '/projectdesigner/logresult/:id',
							meta: { label: 'Logs of a project' },
							name: 'Project Logs',
							component: ProjectLogs,
						},
						{
							path: '/projectdesigner/diagresult/:id',
							meta: { label: 'Diagnostics of a project' },
							name: 'Project Diagnostics',
							component: ProjectDiags,
						},
					]
				},
				{
					path: 'roleeditors/globals',
					meta: {
						label: 'Global Role Editor',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
					children: [
						{
							path: '',
							component: GlobalRoleEditor,
						},
						{
							path: ':id/edit',
							meta: { label: 'Edit a global role' },
							name: 'Edit a global role',
							component: GlobalEditARole
						},
						{
							path: '/roleeditors/globals/create',
							meta: { label: 'Create a new global role' },
							name: 'Create a global role',
							component: GlobalEditARole
						},
					]
				},
				{
					path: 'roleeditors/projects',
					meta: {
						label: 'Project Role Editor',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'list_project_roles');
					},
					children: [
						{
							path: '',
							component: ProjectRoleEditor,
						},
						{
							path: ':id/edit',
							meta: { label: 'Edit a project role' },
							name: 'Edit a project role',
							component: ProjectEditARole,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'edit_project_role');
							},
						},
						{
							path: '/roleeditors/projects/create',
							meta: { label: 'Create a new project role' },
							name: 'Create a project role',
							component: ProjectEditARole,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'add_new_project_role');
							},
						},
					]
				},
				{
					path: 'surveydesigner',
					meta: {
						label: 'Survey Designer',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'list_surveys');
					},
					children: [
						{
							path: '',
							component: SurveyDesigner
						},
						{
							path: '/surveydesigner/selector',
							meta: { label: 'Select a survey type' },
							name: 'Select a survey type',
							component: SurveySelector,
						},
						{
							path: ':id/edit/:type',
							meta: { label: 'Edit a survey' },
							name: 'Edit a survey',
							component: EditSurvey,
						},
					]
				},
				{
					path: 'cms/articleeditor',
					meta: {
						label: 'Article Editor',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
					children: [
						{
							path: '',
							component: ArticleEditor
						},
						{
							path: ':id/edit',
							meta: { label: 'Edit an article' },
							name: 'Edit an article',
							component: EditArticle
						},
					]
				},
				{
					path: 'cms/faqeditor',
					meta: {
						label: 'FAQ Editor',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
					children: [
						{
							path: '',
							component: FaqEditor
						},
						{
							path: ':id/edit',
							name: 'Edit a FAQ',
							component: EditaFaq
						},
						{
							path: '/faqeditor/add',
							name: 'Add a New FAQ',
							component: AddNewFaq
						},
					]
				},
				{
					path: 'cms/octopus-researchers',
					meta: {
						label: 'Octpous Researchers',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
					children: [
						{
							path: '',
							component: OctopusResearchers
						},
						{
							path: ':id/edit',
							name: 'Edit a Researcher',
							component: EditResearcher
						}
					]
				},
				{
					path: 'cms/newsletter-subscribers',
					meta: {
						label: 'Newsletter Subscribers',
					},
					component: NewsletterSubscribers,
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
				},
				{
					path: 'cms/researcher-subscribers',
					meta: {
						label: 'Researcher Subscribers',
					},
					component: ResearcherSubscribers,
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
				},
				{
					path: 'profile',
					name: 'Profile',
					meta: {
						label: 'Profile',
					},
					component: EditProfile
				},
				{
					path: 'settings/defaultsettings',
					meta: {
						label: 'Settings',
					},
					component: {
						render (c) { return c('router-view')}
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
					children: [
						{
						path: '',
							component: Settings
						}
					]
				},
				{
					path: 'users',
					meta: {
						label: 'Users',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'list_users');
					},
					children: [
						{
							path: '',
							component: UsersList,
						},
						{
							path: ':id/edit',
							meta: { label: 'Edit user' },
							name: 'Edit User',
							component: EditUser,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'edit_user');
							},
						},
						{
							path: '/users/add',
							meta: { label: 'Add a new user' },
							name: 'Add User',
							component: EditUser,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'add_new_user');
							},
						}
					]
				},
				{
					path: 'clients',
					meta: {
						label: 'Clients',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'show_client');
					},
					children: [
						{
							path: '',
							component: Clients
						},
						{
							path: ':id/edit',
							meta: { label: 'Edit a client' },
							name: 'Edit Client',
							component: EditClient,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'show_client');
							}
						},
						{
							path: '/clients/add',
							meta: { label: 'Add a client' },
							name: 'Add Client',
							component: AddClient,
							beforeEnter: (to, from, next) => {
								Helper.checkRight(to, next);
							},
						}
					]
				},
				{
					path: 'settings/emailtemplates',
					meta: {
						label: 'Email Templates',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'list_email_templates');
					},
					children: [
						{
							path: '',
							component: Emails,
							meta: {
								label: 'List email templates',
							}
						},
						{
							path: 'create',
							meta: {
								label: 'Create Email Template',
							},
							name: 'Create Email Template',
							component: CreateEmail,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'add_new_email_template');
							},
						},
						{
							path: ':id',
							meta: {
								label: 'Show Email Template',
							},
							name: 'Show Email Tempalte',
							component: ShowEmail,
						},
						{
							path: ':id/edit',
							meta: {
								label: 'Edit Email Tempalate',
							},
							name: 'Edit Email Template',
							component: EditEmail,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'edit_email_template');
							},
						},
						{
							path: ':id/sendEmail',
							meta: {
								label: 'Send Email',
							},
							name: 'Send Email',
							component: SendEmail,
						},
					]
				},
				{
					path: 'settings/emailnotifications',
					meta: {
						label: 'Notifications',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRole(to, next, 'list_notifications');
					},
					children: [
						{
							path: '',
							component: NotificationList,
							meta: {
								label: 'List notifications'
							}
						},
						{
							path: 'create',
							meta: {
								label: 'Create a notification',
							},
							name: 'Create a notification',
							component: EditNotification,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'add_new_notification');
							},
						},
						{
							path: ':id/edit',
							meta: {
								label: 'Edit notification',
							},
							name: 'Edit notification',
							component: EditNotification,
							beforeEnter: (to, from, next) => {
								Helper.checkRole(to, next, 'edit_notification');
							},
						},
					]
				},
				{
					path: 'settings/textcontents',
					meta: {
						label: 'Text contents',
					},
					component: {
						render (c) { return c('router-view') }
					},
					beforeEnter: (to, from, next) => {
						Helper.checkRight(to, next);
					},
					children: [
						{
							path: '',
							component: TextContentList,
							meta: {
								label: 'Text contens'
							}
						},
						{
							path: 'create',
							meta: {
								label: 'Create a text content',
							},
							name: 'Create a text content',
							component: EditTextContent,
						},
						{
							path: ':id/edit',
							meta: {
								label: 'Edit text content',
							},
							name: 'Edit text content',
							component: EditTextContent,
						},
					]
				},
			]
		},
		{
			path: '/',
			redirect: '/login',
			name: 'Auth',
			component: {
				render (c) { return c('router-view') }
			},
			children: [
				{
					path: 'login',
					name: 'Login',
					component: Login
				},
			]
		},
		{
			path: '/500',
			name: '500',
			component: Page500
		},
		{
			path: '/403',
			name: '403',
			component: Page403
		},
		{
			path: '*',
			name: '404',
			component: Page404
		}
	]
}
